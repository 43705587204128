import React, { Fragment } from "react";
import AuthProvider from "./contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import AuthGuard from "./components/AuthGuard";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <Fragment>
      <AuthProvider>
        <BrowserRouter>
          <AuthGuard />
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Fragment>
  );
}

export default App;
