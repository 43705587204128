import { addAuthenticationHeader } from "./auth";
import { postFetch } from "./fetch";
import { apiRoutes } from "./routes";

type Arg = {
  amount: string;
  invoice: string;
  customerNumber: string;
};

export const creditCustomerForPurchase = async (values: Arg) => {
  return addAuthenticationHeader().then((auth) => {
    return postFetch(apiRoutes.giftPoints, { body: values, ...auth }).then(
      async (res) => {
        const result = await res.json();
        return result;
      }
    );
  });
};
