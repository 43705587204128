import React, { useCallback, useState } from "react";
import logo from "../../assets/icon.png";
import useAuthContext from "../../hooks/useAuthContext";
import { handleLogout } from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Nav() {
  const [fetching, setFetching] = useState(false);

  const { isAuthenticated, dispatch } = useAuthContext();
  const navigate = useNavigate();

  const logout = useCallback(() => {
    setFetching(true);
    handleLogout()
      .then(() => {
        toast("Logged out successfully.");
        dispatch({ type: "LOGOUT" });
        navigate("/");
        return true;
      })
      .finally(() => {
        setFetching(false);
      });
  }, [dispatch, navigate]);

  return (
    <header className="bg-black p-5 flex flex-row justify-between items-center">
      <img src={logo} alt="Site Logo" className="w-40 " />
      {isAuthenticated && (
        <button
          onClick={logout}
          disabled={fetching}
          className="bg-blue-600 text-white p-3 text-lg mt-5 rounded-md focus:bg-blue-300 focus:text-slate-800
                  hover:bg-blue-300 hover:text-slate-800 transition-colors duration-200 disabled:opacity-30 disabled:animate-pulse"
        >
          Logout
        </button>
      )}
    </header>
  );
}

export default Nav;
