import React, { useCallback, useEffect, useState } from "react";
import Nav from "../components/layouts/Nav";
import { useNavigate } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext";
import * as Yup from "yup";
import { fetchUser, login, setToken } from "../services/auth";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";

interface LoginRequest {
  email: string;
  password: string;
  remember: boolean;
}
const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(5, "Too short!").required("Required"),
  remember: Yup.boolean().notRequired(),
});

function Login() {
  const [fetching, setFetching] = useState(false);
  const [redirectParams, setRedirectParams] = useState<string | null>(
    localStorage.getItem("member-id")
  );

  const navigate = useNavigate();
  const { dispatch, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      const redirectParams = localStorage.getItem("member-id");
      localStorage.removeItem("member-id");
      setRedirectParams(redirectParams);
      navigate(`/dashboard?member-id=${redirectParams}`);
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = useCallback(
    async function (
      values: LoginRequest,
      {
        resetForm,
        setErrors,
      }: Pick<FormikHelpers<LoginRequest>, "resetForm" | "setErrors">
    ) {
      setFetching(true);
      return login({ data: { ...values } })
        .then(async (res: Response) => {
          const result = await res.json();
          if (result.error) {
            toast("Failed to login");
          } else if (result.status === "success") {
            const token = res.headers.get("Authorization");

            if (token) {
              // Save token to storage.
              return setToken(token).then(async () => {
                return fetchUser().then((user) => {
                  if (user) {
                    dispatch({
                      type: "LOGIN",
                      payload: {
                        user,
                      },
                    });
                    // Registration was succesfull check email to continue
                    resetForm();
                    // Go to app if succesfull
                    localStorage.removeItem("member-id");
                    navigate(`/dashboard?member-id=${redirectParams}`);
                  } else {
                    toast("Something went wrong while trying to log you in.");
                  }
                });
              });
            }
          }
        })
        .catch((e) => {
          console.error(e, "Heyy");
        })
        .finally(() => setFetching(false));
    },
    [dispatch, navigate, redirectParams]
  );

  return (
    <div className="h-screen flex flex-col">
      <Nav />
      <main className="flex flex-col justify-center items-center w-full h-full">
        <div className="flex flex-col bg-white p-3 md:p-10 shadow-lg rounded w-full max-w-sm border">
          <h2 className="text-2xl font-light">Log in to your account</h2>
          <Formik
            initialValues={{
              email: "",
              password: "",
              remember: true,
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { resetForm, setErrors }) =>
              handleLogin(values, { resetForm, setErrors })
            }
          >
            {({ handleChange, handleBlur, handleSubmit, errors, values }) => (
              <form onSubmit={handleSubmit} className="w-full flex flex-col">
                <input
                  type="text"
                  placeholder="email"
                  className="px-1 py-2 border my-4"
                  value={values.email}
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                />
                <input
                  type="text"
                  placeholder="password"
                  className="px-1 py-2 border my-4"
                  value={values.password}
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                />
                {/* <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    checked={values.remember}
                    onChange={handleChange("remember")}
                  />
                  <label htmlFor="remember">Keep me loged in</label>
                </div> */}
                <button
                  className="bg-blue-600 text-white p-3 text-lg mt-5 rounded-md focus:bg-blue-300 focus:text-slate-800
                  hover:bg-blue-300 hover:text-slate-800 transition-colors duration-200 disabled:opacity-30 disabled:animate-pulse"
                  type="submit"
                  disabled={fetching}
                >
                  {fetching ? "Please wait..." : "Login"}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  );
}

export default Login;
