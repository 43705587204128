import React from "react";
import useAuthContext from "../hooks/useAuthContext";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
// import { Text, View } from "react-native";

const AuthGuard = () => {
  const { user } = useAuthContext();
  if (user.loading) {
    return <div>Loadig....</div>;
  }

  return (
    <Routes>
      <Route element={<Dashboard />} path="/dashboard" />
      <Route element={<Login />} path="/" />
      <Route element={<PageNotFound />} path="*" />
    </Routes>
  );
};

export default React.memo(AuthGuard);
