function updateOptions(options?: RequestInit | undefined) {
  const update = { ...options };
  update.headers = {
    ...update.headers,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // stringify all body
  const { body } = update;
  const b = JSON.stringify(body);
  update.body = b;
  return update;
}

export default async function fetcher(
  url: string,
  options?: RequestInit | undefined | object
) {
  const fetchUrl = `https://baffiloyalty.regects.com/api/general-staff${url}`;
  return fetch(fetchUrl, updateOptions(options));
}

export const getFetch: typeof fetcher = (url, opt) =>
  fetcher(url, { ...opt, method: "GET" });
export const postFetch: typeof fetcher = (url, opt) =>
  fetcher(url, { ...opt, method: "POST" });
