import React, { Fragment, useEffect, useState } from "react";
import Nav from "../components/layouts/Nav";
import Section from "../components/layouts/Section";
import Modal from "react-modal";
import { getFetch } from "../services/fetch";
import { apiRoutes } from "../services/routes";
import { addAuthenticationHeader } from "../services/auth";
import { formatDistance } from "date-fns";
import useAuthContext from "../hooks/useAuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { creditCustomerForPurchase } from "../services";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
  },
};

type RequestValues = {
  amount: string;
  invoice: string;
  customerNumber: string;
};
const ValidationSchema = Yup.object().shape({
  amount: Yup.number().required("Required").integer("Only numeric").moreThan(0),
  invoice: Yup.number()
    .required("Required")
    .integer("Only numeric")
    .moreThan(0),
  customerNumber: Yup.number()
    .required("Required")
    .integer("Only numeric")
    .moreThan(0),
});

function Dashboard() {
  const [fetching, setFetching] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!isAuthenticated) {
      const memberId = searchParams.get("member-id");
      if (memberId) {
        localStorage.setItem("member-id", memberId);
      }
      navigate("/");
    }
  }, [isAuthenticated, navigate, searchParams]);

  const handleSubmit = (
    values: RequestValues,
    { resetForm }: Pick<FormikHelpers<RequestValues>, "resetForm" | "setErrors">
  ) => {
    setFetching(true);
    creditCustomerForPurchase(values)
      .then((result) => {
        if (result.status === "error") {
          toast("Failed to send the request");
        } else if (result.status === "success") {
          toast("Succesfull!");
          resetForm();
          closeModal();
        } else {
          const { message } = result;
          toast(message);
        }
        setFetching(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="h-screen flex-col">
      <Nav />
      <Section containerClass="bg-blue-700 p-5 text-white">
        <h1>Give customers points</h1>
        <p>Below are the options that the customer has to earn points.</p>
      </Section>
      <Section
        containerClass="bg-gray-200"
        className="flex flex-col md:flex-row justify-center"
      >
        <div className="h-full p-10 mx-auto md:mx-0">
          <Card onClickHandler={openModal} />
        </div>
        <PointHistoryComponent />
      </Section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Credit a customer with number"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        overlayClassName="fixed inset-0 bg-black/50"
      >
        <h2>Credit a customer with number</h2>
        <p>
          Enter the amount of points and a customer number to credit the
          customer.
        </p>
        <Formik
          initialValues={{
            amount: "",
            invoice: "",
            customerNumber: searchParams.get("member-id") ?? "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, { resetForm, setErrors }) =>
            handleSubmit(values, { resetForm, setErrors })
          }
        >
          {({ handleChange, handleBlur, handleSubmit, errors, values }) => (
            <Fragment>
              <form className="w-full flex-col my-3" onSubmit={handleSubmit}>
                <input
                  type="number"
                  placeholder="Amount sold"
                  min={"0"}
                  value={values.amount}
                  onChange={handleChange("amount")}
                  onBlur={handleBlur("amount")}
                  className="px-1 py-2 border my-2 w-full"
                />
                <small className="text-xs text-red-500 -mt-1 block">
                  {errors.amount}
                </small>
                <input
                  type="number"
                  placeholder="Invoice number"
                  min={"0"}
                  value={values.invoice}
                  onChange={handleChange("invoice")}
                  onBlur={handleBlur("invoice")}
                  className="px-1 py-2 border my-2 w-full"
                />
                <small className="text-xs text-red-500 -mt-1 block">
                  {errors.invoice}
                </small>
                <input
                  type="number"
                  min={"0"}
                  placeholder="Membership number"
                  value={values.customerNumber}
                  onChange={handleChange("customerNumber")}
                  onBlur={handleBlur("customerNumber")}
                  className="px-1 py-2 border my-2 w-full"
                />
                <small className="text-xs text-red-500 -mt-1 block">
                  {errors.customerNumber}
                </small>
                <div className="flex justify-end gap-4 items-center mt-2">
                  <button
                    disabled={fetching}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Credit Customer
                  </button>
                  <button className="btn btn-outline" onClick={closeModal}>
                    close
                  </button>
                </div>
              </form>
            </Fragment>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

interface CardProps {
  onClickHandler: () => void;
}
function Card({ onClickHandler }: CardProps) {
  return (
    <div
      onClick={onClickHandler}
      className="shadow-lg hover:shadow-none transition-all bg-white hover:bg-gray-50 cursor-pointer w-full max-w-xs p-10 rounded-md"
    >
      <p className="text-2xl font-medium mb-3">Customer Number</p>
      <p className="font-light">
        Add points to a customer account using a customer number.
      </p>
    </div>
  );
}

interface PointHistory {
  reward_title: string | null;
  points: number;
  invoice_number: number;
  amount_sold: number;
  created_at: string;
  segment_details: any[];
  customer_details: {
    name: string;
    avatar: string;
    points: number;
    number: string;
  };
  icon: string;
  color: string;
  description: string;
}

function PointHistoryComponent() {
  const [history, setHistory] = useState<PointHistory[]>([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchHistory = async () => {
      setFetching(true);
      addAuthenticationHeader().then(async (auth) => {
        const request = await getFetch(apiRoutes.history, {
          ...auth,
        });
        const result = await request.json();
        setHistory(result);
        setFetching(false);
      });
    };
    fetchHistory();
  }, []);

  return (
    <div className="w-full max-w-md py-10 mx-auto md:mx-0">
      <div className="bg-white p-3 shadow-lg">
        <h2 className="border-b py-2">Point History</h2>
        {fetching && <div>Loading...</div>}
        {history.map((pointHistory, index) => (
          <Point
            key={`${pointHistory.created_at}-${index}`}
            pointHistory={pointHistory}
          />
        ))}
      </div>
    </div>
  );
}

interface PointProps {
  pointHistory: PointHistory;
}

function Point({ pointHistory }: PointProps) {
  return (
    <div className="flex flex-col items-start gap-2 my-10 sm:my-5">
      <div className="flex gap-2 items-start">
        <div className="p-0.5 bg-white rounded-full">
          <div className="bg-green-600 p-2 text-white text-lg font-semibold rounded-full">
            TM
          </div>
        </div>
        <div>
          <strong className="text-sm">
            {pointHistory.points} points,{" "}
            {formatDistance(new Date(pointHistory.created_at), Date.now(), {
              addSuffix: true,
            })}
          </strong>
          <div className="font-light text-xs">
            {pointHistory.customer_details.name} (
            {pointHistory.customer_details.number})
          </div>
          <div className="font-light text-xs">{pointHistory.description}</div>
        </div>
      </div>
      {pointHistory.invoice_number && (
        <div className="flex flex-row justify-between w-9/12 mx-auto">
          <div className="text-sm flex flex-col items-end sm:items-start w-full sm:w-auto">
            Amount Sold
            <span className="text-lg bg-gray-300 rounded p-1 font-medium text-green-900">
              #{pointHistory.amount_sold}
            </span>
          </div>
          <div className="text-sm flex flex-col items-end sm:items-start w-full sm:w-auto">
            Invoice Number
            <span className="text-lg bg-gray-300 rounded p-1 font-medium text-green-900">
              #{pointHistory.invoice_number}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
export default Dashboard;
