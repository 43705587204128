import type { User } from "../../contexts/types";
import fetcher, { postFetch } from "../fetch";
import { apiRoutes } from "../routes";

export const login = async function ({ data }: { data: any }) {
  return fetcher(apiRoutes.login, { body: data, method: "POST" })
    .then((res) => {
      return res;
    })
    .catch((e) => e);
};

export const fetchUser = async function () {
  return addAuthenticationHeader().then(async (auth) => {
    if (!auth) return null;
    const options = { method: "GET", ...auth };
    return fetcher(apiRoutes.profile, options).then(async (res) => {
      const result = await res.json();
      return result.data as User;
    });
  });
};

export const handleLogout = async () => {
  const token = await getToken();
  if (token) {
    return addAuthenticationHeader().then(async (auth) => {
      return postFetch(apiRoutes.logout, auth).then(() => {
        setToken(null);
        return true;
      });
    });
  }
};

export const setToken = async (token: string | null) => {
  localStorage.setItem("userToken", JSON.stringify(token));
};

export const getToken = async (): Promise<string | null> => {
  const token = await localStorage.getItem("userToken");
  return token ? JSON.parse(token) : null;
};

export const addAuthenticationHeader = async () => {
  let token = await getToken();
  if (token) {
    return { headers: { Authorization: `Bearer ${token}` } };
  }
};
