import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import type { ACTIONTYPE, State } from "./types";
import { fetchUser } from "../services/auth";

const initialState: State = {
  isAuthenticated: false,
  user: { loading: false, data: null },
};

const reducer = (state: State, action: ACTIONTYPE) => {
  switch (action.type) {
    case "LOGIN": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...state.user,
          data: user,
        },
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: {
          ...state.user,
          loading: false,
          data: null,
        },
      };
    }
    case "UPDATE_PROFILE": {
      const { user } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          data: user,
        },
      };
    }
    case "REQUESTING": {
      const { key, status } = action.payload;
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: status,
        },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface AuthContextProps extends State {
  dispatch: React.Dispatch<ACTIONTYPE>;
  dispatchNewRequest: (key: keyof Omit<State, "isAuthenticated">) => void;
  dispatchEndRequest: (key: keyof Omit<State, "isAuthenticated">) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  ...initialState,
  dispatch: () => {},
  dispatchNewRequest: () => {},
  dispatchEndRequest: () => {},
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchNewRequest = useCallback(
    (key: keyof Omit<State, "isAuthenticated">) => {
      dispatch({
        type: "REQUESTING",
        payload: { key: "user", status: true },
      });
    },
    [dispatch]
  );

  const dispatchEndRequest: typeof dispatchNewRequest = useCallback(
    (key) => {
      dispatch({
        type: "REQUESTING",
        payload: { key: "user", status: false },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      dispatchNewRequest("user");
      // The Id of the user. defaults to zero if the user ID does not exist
      // login
      fetchUser().then((user) => {
        if (user) {
          dispatch({
            type: "LOGIN",
            payload: {
              user: user,
            },
          });
          dispatchEndRequest("user");
        } else {
          dispatch({
            type: "LOGOUT",
          });
        }
      });
    })();
  }, [dispatchEndRequest, dispatchNewRequest]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        dispatchEndRequest,
        dispatchNewRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
